import { doc, getDoc, runTransaction } from "firebase/firestore";
import { auth, db} from "../firebase"; // Ensure functions is correctly imported
import axios from "axios";

export const fetchUserData = async (
  navigate,
  setUserEmail,
  setCoins,
  setSavedDreams,
  setLoading
) => {
  const fakeLoadingTime = 3200; // Minimum loading time in milliseconds
  const startTime = Date.now(); // Record the start time for the loading duration

  const retryFetch = async (retries) => {
    if (retries === 0) {
      console.error("No such document after retries!");
      const elapsedTime = Date.now() - startTime;
      const remainingTime = fakeLoadingTime - elapsedTime;
      setTimeout(() => setLoading(false), Math.max(remainingTime, 0)); // Ensure fake loading time
      return;
    }

    try {
      const user = await new Promise((resolve) => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
          unsubscribe();
          resolve(user);
        });
      });

      if (!user) {
        navigate("/login");
        return;
      }

      const userDoc = await getDoc(doc(db, "users", user.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setUserEmail(userData.email);
        setCoins(userData.coins);
        setSavedDreams(userData.dreams || []);
        const elapsedTime = Date.now() - startTime;
        const remainingTime = fakeLoadingTime - elapsedTime;
        setTimeout(() => setLoading(false), Math.max(remainingTime, 0)); // Ensure fake loading time
      } else {
        console.warn("Document not found. Retrying...");
        setTimeout(() => retryFetch(retries - 1), 500); // Retry after 500ms
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      const elapsedTime = Date.now() - startTime;
      const remainingTime = fakeLoadingTime - elapsedTime;
      setTimeout(() => setLoading(false), Math.max(remainingTime, 0)); // Ensure fake loading time
    }
  };

  await retryFetch(5); // Retry up to 5 times
};

const createValidationPrompt = (dream) => {
  return `Ar šis tekstas yra sapno aprašymas? Sapnas tai miegančiam kylantys vaizdai, mintys. 
Jei tai nėra sapno aprašymas arba yra kažkas kito, atsakyk "ne". Jei tai yra sapno aprašymas, atsakyk "taip". 
Būtinai atsakyk tiksliai kaip parašiau: "ne" arba "taip". Nerašyk nieko daugiau ir nesuteik jokių kitų instrukcijų ar informacijos.

Sapnavau: ${dream}`;
};

const createDreamAnalysisPrompt = (dream, age, gender, mood) => {
  return `Sapnas: "${dream}".\n\n
Pateik interpretaciją remdamasis šiais duomenimis:
- **Amžius**: ${age} metų.
- **Lytis**: ${gender}.
- **Nuotaika prieš miegą**: ${mood}.

**Tavo užduotis**:
1. Išsamiai analizuok sapno turinį ir pateik pačią tiksliausią sapno interpretaciją bei analizę.
2. Paaiškink, kaip sapno vaizdai, veikėjai ar situacijos gali būti susiję su sapnuotojo pasąmonės signalais, gyvenimo situacija ar emocine būsena.
3. Pateik įžvalgas apie galimą sapno prasmę ir kaip jis galėtų paveikti sapnuotojo kasdienybę arba sprendimus.
4. Įtrauk, jei įmanoma, simbolių reikšmes ar bendrus sapnų aiškinimo principus, tačiau būk asmeniškas ir pritaikyk atsakymą prie sapno detalių, nuotaikos ir amžiaus.

Naudok šią struktūrą:
1.Sapno turinio analyzė
2.Pasąmonės signalai
3.Sapno prasmė
4.Simbolių reikšmės
5.Apibendrinimas

Atsakyk taisyklinga, aiškia ir informatyvia lietuvių kalba be gramatiškų klaidų, pateikdamas vertę sapnuotojui. Nerašyk nieko, kas nėra susiję su sapnu ar jo analize. Ir naudok kreipinius į 
vartotoją kaip jūs, jūsų. Niekada neminėk, kad esi AI. Iš karto pradėk nuo sapno interpretacijos.`;
};

const API_URL = "https://us-central1-sapnasis.cloudfunctions.net/callOpenAI";

export const callChatGPT = async (prompt) => {
  try {
    const response = await axios.post(
      API_URL,
      { prompt },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer YOUR_API_KEY`, // Replace with your OpenAI API key logic
        },
        withCredentials: true,
      }
    );
    return response.data.content;
  } catch (error) {
    console.error("Error calling OpenAI API:", error.message);
    throw new Error("Failed to fetch response from GPT.");
  }
};

export const validateAndAnalyzeDream = async (
  dream,
  age,
  gender,
  mood,
  setCoins,
  setError,
  auth,
  db,
  setSavedDreams,
  setSelectedDream
) => {
  try {
    // Validate the dream first
    const validationPrompt = createValidationPrompt(dream);
    const validationResponse = await callChatGPT(validationPrompt);

    if (validationResponse.toLowerCase() !== "taip") {
      const user = auth.currentUser;
      const userDocRef = doc(db, "users", user.uid);

      await runTransaction(db, async (transaction) => {
        const userDoc = await transaction.get(userDocRef);
        if (!userDoc.exists()) {
          throw new Error("User does not exist!");
        }
        const newCoins = userDoc.data().coins - 1;
        transaction.update(userDocRef, { coins: newCoins });
        setCoins(newCoins);
      });

      setError("Tai nėra sapno aprašymas. Jūs ką tik iššvaistėte vieną savo žetoną...");
      return;
    }

    // Analyze the dream
    const analysisPrompt = createDreamAnalysisPrompt(dream, age, gender, mood);
    const interpretation = await callChatGPT(analysisPrompt);

    const user = auth.currentUser;
    const userDocRef = doc(db, "users", user.uid);
    const newDream = {
      description: dream,
      interpretation,
      date: new Date().toISOString(),
    };

    await runTransaction(db, async (transaction) => {
      const userDoc = await transaction.get(userDocRef);
      if (!userDoc.exists()) {
        throw new Error("User does not exist!");
      }

      // Deduct coins and update `dreams` array
      const userData = userDoc.data();
      const newCoins = userData.coins - 1;
      const newDreams = [...userData.dreams, newDream];

      // Check if the dream already exists in `userAnalyzedDreamsHistory`
      const analyzedHistory = userData.userAnalyzedDreamsHistory || [];
      const existsInHistory = analyzedHistory.some(
        (history) => history.description === dream
      );

      // If not, add the dream to `userAnalyzedDreamsHistory`
      if (!existsInHistory) {
        analyzedHistory.push({
          ...newDream,
          analyzedAt: new Date().toISOString(), // Add timestamp of analysis
        });
      }

      transaction.update(userDocRef, {
        coins: newCoins,
        dreams: newDreams,
        userAnalyzedDreamsHistory: analyzedHistory,
      });

      setCoins(newCoins);
      setSavedDreams(newDreams);
    });

    setSelectedDream(newDream);
  } catch (error) {
    console.error("Dream analysis failed:", error);
    setError("Nepavyko išanalizuoti sapno. Bandykite dar kartą vėliau.");
    throw error;
  }
};
