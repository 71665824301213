import React, { useEffect, useRef } from "react";
import "../styles/servicesSection.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import personalSvg from "../images/personal.svg";
import bookSvg from "../images/book.svg";
import mindfull from "../images/mindfull.svg";
import { Link } from "react-router-dom";

const ServicesSection = () => {
  const serviceRefs = useRef([]); // Store refs for each service card

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("in-view");
          }
        });
      },
      {
        threshold: 0.3, // Trigger animation when 30% of the element is visible
      }
    );

    // Capture the current ref array at this moment in a local variable
    const currentRefs = serviceRefs.current;

    // Observe each element in the array
    currentRefs.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    // Cleanup using the same captured refs
    return () => {
      currentRefs.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []); // Only run once on mount/unmount

  return (
    <section id="services-section" className="services-section">
      <h2>Su Sapnasis galite:</h2>
      {/* Added a brief paragraph to provide context and include more keywords. */}
      <p className="services-intro">
        Tyrinėkite sapnų simboliką, reikšmes ir pasąmonės siunčiamus ženklus. Mūsų
        pažangios technologijos padeda suprasti sapnų interpretaciją ir suteikia galimybę
        lengvai valdyti savo sapnų istoriją.
      </p>

      <div className="services-container">
        {[
          {
            img: personalSvg,
            title: "Sapnų analizė",
            text: "Analizuokite savo sapnus naudodami pažangias technologijas.",
            alt: "Sapnų analizė piktograma",
          },
          {
            img: bookSvg,
            title: "Sapnų istorija",
            text: "Išsaugokite sapnus paskyroje ir stebėkite jų raidos eigą.",
            alt: "Sapnų istorija piktograma",
          },
          {
            img: mindfull,
            title: "Pasąmonės ženklai",
            text: "Sužinokite, ką sako jūsų pasąmonė per sapnų simbolius.",
            alt: "Pasąmonės ženklai piktograma",
          },
        ].map((service, index) => (
          <div
            key={index}
            className="service animation-left"
            // Assign each element's ref to our serviceRefs array
            ref={(el) => (serviceRefs.current[index] = el)}
          >
            <div className="service-image-container">
              <img
                src={service.img}
                alt={service.alt}
                className="service-image"
              />
            </div>
            <h3>{service.title}</h3>
            <p>{service.text}</p>
          </div>
        ))}
      </div>

      <Link to="/login" className="cta-button">
        Pradėti
      </Link>
    </section>
  );
};

export default ServicesSection;
