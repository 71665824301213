import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/heroSection.css";
import dreamerSvg from "../images/DreamerWomanWhiteGold.png";

const HeroSection = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/login"); 
  };

  return (
    <section className="hero-section" id="hero">
      <div className="hero-content">
        <div className="hero-image-container">
          {/* Updated alt text to be descriptive + keyword-related */}
          <img
            src={dreamerSvg}
            alt="Sapnų analizės moteris"
            className="hero-image"
          />
        </div>
        <div className="hero-text">
          {/* Primary keyword in H1 */}
          <h1>
            Išanalizuok savo sapnus su <span className="highlight">Sapnasis</span>
          </h1>
          {/* Additional keywords in H2 for SEO */}
          <h2>Sapnų interpretacija, reikšmės ir simbolika</h2>
          <p>
            Sužinok, ką reiškia tavo sapnai, bei kaip jie gali padėti suprasti
            pasąmonės signalus. Pažangių technologijų pagalba gausi išsamią sapnų
            analizę lietuviškai – greitai ir patogiai.
          </p>
          <button className="hero-button" onClick={handleButtonClick}>
            Pradėk nemokamai
          </button>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
