import React, { useEffect, useState } from "react";
import "../styles/loadingScreen.css";
import moonIcon from "../images/LogoBig.png"; // Import a moon icon image

const LoadingScreen = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => (prev < 100 ? prev + 1 : 100));
    }, 30); // Increase progress every 30ms
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="loading-container">
      <div className="progress-circle">
        <div
          className="progress-fill"
          style={{ height: `${progress}%` }}
        ></div>
        <img src={moonIcon} alt="Moon Icon" className="moon-icon" />
      </div>
      <p className="loading-text">Palaukite...</p>
    </div>
  );
};

export default LoadingScreen;
