import React, { useState } from "react";
import "../styles/faqSection.css"; // Import the CSS file

const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqData = [
    {
      question: "Kas yra Sapnasis?",
      answer:
        "Sapnasis – tai sapnų analizės ir interpretacijos įrankis, paremtas pažangiomis technologijomis. Jis padeda jums suprasti pasąmonės siunčiamas žinutes per sapnus bei pateikia jums išsamias sapnų intepretacijas lietuviškai",
    },
    {
      question: "Kaip veikia sapnų analizė?",
      answer:
        "Pateikus sapno aprašymą, nuotaiką, amžių, lytį, Sapnasis naudoja pažangias technologijas atsižvelgdamas į jūsų pateiktus parametrus, kad pateiktų jums išsamią sapno interpretaciją ir paaiškintų jo simboliką bei pasąmones siunčiamas žinutes.",
    },
    {
      question: "Ar galiu tai naudoti kaip sapnų dienoraštį?",
      answer:
        "Taip. Prisijungę galėsite išsaugoti kiekvieną sapną ir bet kada peržiūrėti savo sapnų įrašus.",
    },
    {
      question: "Ar Sapnasis yra nemokamas?",
      answer:
        "Galite pradėti visiškai nemokamai. Prisiregistravus gaunate penkias nemokamas sapnų analizes. Toliau yra naudojami žetonai kurie visiškai nebrangūs. Už sapno analizavimą kaina svyruoja nuo 0.09 eur iki 0.16 eur priklausomai nuo pasirinkto plano.",
    },
    {
      question: "Ar sapnų reikšmės yra tikslios?",
      answer:
        "Taip, stengiamės pateikti kuo tikslesnę interpretaciją, pasitelkdami interneto šaltinius, knygas ir kitus prieinamus duomenis. Vis dėlto kiekvieno žmogaus patirtis yra unikali, todėl rekomenduojame atsižvelgti ir į individualius aspektus.",
    },
    {
      question: "Kuom yra naudingos sapnų reikšmės?",
      answer:
        "Sapnų reikšmės padeda suvokti savo mintis, emocijas ir pasąmonės siunčiamus signalus. Išsamiau suprasdami sapnų simboliką, galite geriau pažinti save, įgyti daugiau emocinės pusiausvyros ir greičiau rasti atsakymus į vidinius klausimus.",
    },
  ];

  const toggleFAQ = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <section className="faq-section" id="faq-section">
      <h2>Dažniausiai užduodami klausimai</h2>
      <div className="faq-container">
        {faqData.map((item, index) => (
          <div
            key={index}
            className={`faq-item ${activeIndex === index ? "active" : ""}`}
          >
            <button className="faq-question" onClick={() => toggleFAQ(index)}>
              <span>{item.question}</span>
              {/* Display different icons for open/closed state */}
              {activeIndex === index ? (
                <i className="fa-solid fa-chevron-up faq-icon"></i>
              ) : (
                <i className="fa-solid fa-chevron-down faq-icon"></i>
              )}
            </button>
            <div
              className={`faq-answer ${
                activeIndex === index ? "expanded" : "collapsed"
              }`}
            >
              <p>{item.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FAQSection;
