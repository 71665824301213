import React, { useState } from "react";
import { auth } from "../firebase";
import {
  sendEmailVerification,
} from "firebase/auth";
import { Box, Button, Typography, Alert } from "@mui/material";

const VerificationCard = ({email, onVerify }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleResendVerification = async () => {
    setLoading(true);
    setMessage("");

    try {
      await sendEmailVerification(auth.currentUser);
      setMessage("Patvirtinimo laiškas išsiųstas iš naujo.");
    } catch (error) {
        console.log(error);
      setMessage("Įvyko klaida. Bandykite dar kartą.");
    } finally {
      setLoading(false);
    }
  };

  const handleCheckVerification = async () => {
    await auth.currentUser.reload();
    if (auth.currentUser.emailVerified) {
      onVerify();
    } else {
      setMessage("El. paštas dar nepatvirtintas.");
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", padding: 1 }}>
      <i
        className="fa-solid fa-envelope"
        style={{ fontSize: "3rem", color: "#fb639c", marginBottom: "16px" }}
      ></i>
      <Typography variant="h5" sx={{ marginBottom: 2,textAlign:"center" }}>
        Prašome patvirtinti savo el. paštą
      </Typography>
      <Typography variant="h7" sx={{ marginBottom: 2,textAlign:"center" }}>
       {email}
      </Typography>
      <Typography sx={{ textAlign: "center", marginBottom: 3 }}>
        Atidarykite savo el. paštą ir paspauskite patvirtinimo nuorodą. Tuomet paspauskite "PATVIRTINAU" mygtuką.
      </Typography>
      {message && <Alert severity="info">{message}</Alert>}
      <Button
        fullWidth
        variant="contained"
        onClick={handleCheckVerification}
        disabled={loading}
        sx={{ marginBottom: 2, backgroundColor: "#fb639c" }}
      >
        Patvirtinau
      </Button>
      <Button fullWidth variant="outlined" onClick={handleResendVerification} disabled={loading}>
        Siųsti patvirtinimo laišką iš naujo
      </Button>
    </Box>
  );
};

export default VerificationCard;
