import React, { useState } from "react";
import "../styles/footer.css";
import { Link } from "react-scroll";
import "@fortawesome/fontawesome-free/css/all.min.css";

const Footer = () => {
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: "", text: "" });

  const handleOpen = async (title, fileName) => {
    try {
      const content = await import(`../content/${fileName}`);
      const text = await fetch(content.default).then((response) =>
        response.text()
      );
      setModalContent({ title, text });
      setOpen(true);
    } catch (error) {
      console.error("Error loading modal content:", error);
      setModalContent({ title: "Error", text: "Failed to load content." });
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section social">
          <h3>Soc. Tinklai</h3>
          <div className="social-icons">
            <a href="https://www.facebook.com/profile.php?id=61559920184134" target="_blank" rel="noreferrer">
              <i className="fab fa-facebook"></i>
            </a>
            <a href="https://www.instagram.com/sapnasis.lt/" target="_blank" rel="noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://www.tiktok.com/@sapnasis" target="_blank" rel="noreferrer">
              <i className="fab fa-tiktok"></i>
            </a>
          </div>
        </div>
        <div className="footer-section navigation">
          <h3>Navigacija</h3>
          <div className="nav-links">
            <Link to="hero" smooth duration={500}>
              Pradžia
            </Link>
            <Link to="services-section" smooth duration={500}>
              Paslaugos
            </Link>
            <Link to="about-section" smooth duration={500}>
              Apie
            </Link>
            <Link to="reviews-section" smooth duration={500}>
              Atsiliepimai
            </Link>
            <a href="/login">Prisijungti</a>
          </div>
        </div>
        <div className="footer-section policies">
          <h3>Politikos</h3>
          <div className="policy-links">
            <button
              className="policy-button"
              onClick={() =>
                handleOpen("Privatumo politika", "privacyPolicy.txt")
              }
            >
              Privatumo politika
            </button>
            <button
              className="policy-button"
              onClick={() => handleOpen("Naudojimo sąlygos", "termsOfUse.txt")}
            >
              Naudojimo sąlygos
            </button>
            <button
              className="policy-button"
              onClick={() => handleOpen("Grąžinimų politika", "refundPolicy.txt")}
            >
              Grąžinimų politika
            </button>
          </div>
        </div>
        <div className="footer-section contact">
          <h3>Kontaktai</h3>
          <div className="contact-info">
            <i className="fas fa-envelope"></i>
            <span>sapnasisinfo@gmail.com</span>
          </div>
        </div>
      </div>
      <p className="footer-note">
        © {new Date().getFullYear()} Sapnasis. All rights reserved.
      </p>

      {open && (
        <div className="modal-overlay" onClick={handleClose}>
          <div className="modal-card" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close" onClick={handleClose}>
              <i className="fas fa-times"></i>
            </button>
            <div className="modal-header">
              <h2>{modalContent.title}</h2>
            </div>
            <div className="modal-body">
              <p>{modalContent.text}</p>
            </div>
          </div>
        </div>
      )}
    </footer>
  );
};

export default Footer;
