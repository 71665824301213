import React, { useEffect, useState, useCallback } from "react";
import "../styles/dreamForm.css";
import { auth, db } from "../firebase";
import { validateAndAnalyzeDream } from "../utils/dashboardUtils";

const DreamForm = ({
  dream,
  onChange,
  analyzing,
  setAnalyzing,
  error,
  onErrorClose,
  setError,
  coins,
  setCoins,
  savedDreams,
  setSavedDreams,
  setSelectedDream,
  setActiveView,
}) => {
  const maxLength = 600; // Adjusted to reflect input limit
  const [open, setOpen] = useState(false);
  const [selectedMood, setSelectedMood] = useState("");
  const [gender, setGender] = useState("moteris");
  const [age, setAge] = useState(50);

  // Load gender and age from localStorage on component mount
  useEffect(() => {
    const storedGender = localStorage.getItem("dreamFormGender");
    const storedAge = localStorage.getItem("dreamFormAge");

    if (storedGender) setGender(storedGender);
    if (storedAge) setAge(Number(storedAge)); // Ensure age is a number
  }, []);

  useEffect(() => {
    if (error) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [error]);

  const handleClose = () => {
    setOpen(false);
    onErrorClose();
  };

  const handleAnalyze = useCallback(async () => {
    if (!dream.trim()) {
      setError("Prašome aprašyti savo sapną");
      return;
    }
    if (!selectedMood) {
      setError("Pasirinkite nuotaiką");
      return;
    }
    if (!age || age < 1 || age > 100) {
      setError("Nurodykite tinkamą amžių");
      return;
    }
    if (!gender) {
      setError("Pasirinkite lytį");
      return;
    }

    if (coins <= 0) {
      setActiveView("PurchaseCoins");
      return;
    }

    setError("");
    setAnalyzing(true);

    // Save gender and age to localStorage on successful submission
    localStorage.setItem("dreamFormGender", gender);
    localStorage.setItem("dreamFormAge", age.toString());

    try {
      await validateAndAnalyzeDream(
        dream,
        age,
        gender,
        selectedMood,
        setCoins,
        setError,
        auth,
        db,
        setSavedDreams,
        setSelectedDream
      );
    } catch (error) {
      setError("Įvyko klaida analizuojant. Pamėginkite vėliau");
      console.error(error);
    } finally {
      setAnalyzing(false);
    }
  }, [
    dream,
    age,
    gender,
    selectedMood,
    coins,
    setCoins,
    setError,
    setActiveView,
    setAnalyzing,
    setSavedDreams,
    setSelectedDream,
  ]);

  return (
    <div className="dream-form">
      <h1>Analizuoti sapną</h1>
      <div className="form-card">
        <div className="form-label-group">
          <i className="fa-solid fa-moon"></i>
          <label className="form-label" htmlFor="dream">
            Sapnas
          </label>
        </div>
        <textarea
          id="dream"
          className="dream-textarea"
          placeholder="Sapnavau kaip mane vijosi vilkų būrys ir.."
          value={dream}
          onChange={onChange}
          maxLength={maxLength}
        ></textarea>
        <div className="character-count">
          {dream.length}/{maxLength}
        </div>

        <div className="form-group">
          <div className="form-label-group">
            <i className="fa-solid fa-arrow-up-9-1"></i>
            <label className="form-label" htmlFor="age-slider">
              Amžius
            </label>
          </div>
          <input
            id="age-slider"
            type="range"
            className="age-slider"
            min="1"
            max="100"
            value={age}
            onChange={(e) => setAge(Number(e.target.value))}
          />
          <div className="slider-value">{age}</div>
        </div>

        <div className="form-group">
          <div className="form-label-group">
            <i className="fa-solid fa-venus-mars"></i>
            <span className="form-label">Lytis</span>
          </div>
          <div className="form-radio-group">
            <div
              className={`gender-radio ${
                gender === "moteris" ? "selected" : ""
              }`}
              onClick={() => setGender("moteris")}
            >
              <i className="fa-solid fa-check"></i>
            </div>
            <span className="gender-label">Moteris</span>
            <div
              className={`gender-radio ${gender === "vyras" ? "selected" : ""}`}
              onClick={() => setGender("vyras")}
            >
              <i className="fa-solid fa-check"></i>
            </div>
            <span className="gender-label">Vyras</span>
          </div>
        </div>

        <div className="form-group">
          <div className="form-label-group">
            <i className="fa-solid fa-thumbs-up"></i>
            <span className="form-label">Nuotaika prieš miegą</span>
          </div>
          <div className="form-mood-group">
            <label
              className={`mood-radio ${
                selectedMood === "neutralus" ? "selected" : ""
              }`}
              onClick={() => setSelectedMood("neutralus")}
            >
              <i className="fa-solid fa-face-meh"></i>
              <span className="mood-label">Neutralus</span>
            </label>
            <label
              className={`mood-radio ${
                selectedMood === "laimingas" ? "selected" : ""
              }`}
              onClick={() => setSelectedMood("laimingas")}
            >
              <i className="fa-solid fa-face-smile"></i>
              <span className="mood-label">Laimingas</span>
            </label>
            <label
              className={`mood-radio ${
                selectedMood === "liudnas" ? "selected" : ""
              }`}
              onClick={() => setSelectedMood("sliudnas")}
            >
              <i className="fa-solid fa-face-frown"></i>
              <span className="mood-label">Liūdnas</span>
            </label>
            <label
              className={`mood-radio ${
                selectedMood === "stresuotas" ? "selected" : ""
              }`}
              onClick={() => setSelectedMood("stresuotas")}
            >
              <i className="fa-solid fa-face-tired"></i>
              <span className="mood-label">Stresuotas</span>
            </label>
            <label
              className={`mood-radio ${
                selectedMood === "piktas" ? "selected" : ""
              }`}
              onClick={() => setSelectedMood("piktas")}
            >
              <i className="fa-solid fa-face-angry"></i>
              <span className="mood-label">Piktas</span>
            </label>
            <label
              className={`mood-radio ${
                selectedMood === "pavarges" ? "selected" : ""
              }`}
              onClick={() => setSelectedMood("pavarges")}
            >
              <i className="fa-solid fa-face-rolling-eyes"></i>
              <span className="mood-label">Pavargęs</span>
            </label>
          </div>
        </div>

        <div className="form-actions">
          <button
            className={`submit-button ${analyzing ? "disabled" : ""}`}
            onClick={handleAnalyze}
            disabled={analyzing}
          >
            {analyzing ? (
              <div className="spinner"></div>
            ) : (
              <>
                Analizuoti (
                <span className="button-coin">
                  1<i className="fa-solid fa-coins"></i>
                </span>
                )
              </>
            )}
          </button>
        </div>
      </div>

      {open && (
        <div className="error-snackbar">
          <span className="error-message">{error}</span>
          <button className="close-snackbar" onClick={handleClose}>
            ✕
          </button>
        </div>
      )}
    </div>
  );
};

export default DreamForm;
