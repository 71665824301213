import React, { useState, useEffect } from "react";
import { doc, updateDoc, arrayRemove } from "firebase/firestore";
import { db, auth } from "../firebase"; // Import your firebase config
import "../styles/savedDreamDetail.css";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

const SavedDreamDetail = ({ dream, onBack, onDelete }) => {
  const [checkedModal, setCheckedModal] = useState(false);

  useEffect(() => {
    setCheckedModal(true);
  }, []);

  const handleDelete = async () => {
    const user = auth.currentUser;
    const userDocRef = doc(db, "users", user.uid);

    try {
      await updateDoc(userDocRef, {
        dreams: arrayRemove(dream),
      });
      onDelete(dream); // Call the callback to update the state
      onBack(); // Navigate back to the main screen
    } catch (error) {
      console.error("Error deleting dream:", error);
    }
  };

  return (
    <div className={`saved-dream-detail ${checkedModal ? "visible" : ""}`}>
      <h1>Sapno analizė</h1>
      <div className="saved-dream-card-detail">
        <div className="saved-dream-actions">
          <button className="icon-button back-button" onClick={onBack}>
            <i className="fa-solid fa-arrow-left"></i>
          </button>
          <button className="icon-button delete-button" onClick={handleDelete}>
            <i className="fa-solid fa-trash"></i>
          </button>
        </div>
        <div className="saved-dream-content">
          <p className="dream-date">{formatDate(dream.date)}</p>
          <h2 className="dream-subtitle">Analizuotas sapnas:</h2>
          <p className="dream-text">{dream.description}</p>
          <h2 className="dream-subtitle">Sapno Analizė:</h2>
          <p className="dream-text">{dream.interpretation}</p>
        </div>
      </div>
    </div>
  );
};

export default SavedDreamDetail;
