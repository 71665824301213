import React, { useState } from "react";
import { httpsCallable } from "firebase/functions";
import "../styles/PurchaseCoinsModal.css";
import { auth, functions } from "../firebase";
import { useStripe } from "@stripe/react-stripe-js";
import { ClipLoader } from "react-spinners";

const PurchaseCoinsModal = () => {
  const stripe = useStripe();
  const [isLoading, setIsLoading] = useState(false); // Track global loading state

  const handlePurchase = async (priceId, coinsToAdd) => {
    const createCheckoutSession = httpsCallable(
      functions,
      "createStripeCheckoutSession"
    );

    const userId = auth.currentUser ? auth.currentUser.uid : null;
    if (!userId) {
      console.error("User is not authenticated");
      return;
    }

    setIsLoading(true); // Set global loading state

    try {
      const { data } = await createCheckoutSession({
        priceId,
        successUrl: `${window.location.origin}/dashboard?success=true`,
        cancelUrl: `${window.location.origin}/dashboard`,
        metadata: {
          coins: coinsToAdd,
        },
        client_reference_id: userId,
      });

      const { sessionId } = data;
      const result = await stripe.redirectToCheckout({ sessionId });

      if (result.error) {
        console.error(result.error.message);
      }
    } catch (error) {
      console.error("Error creating Stripe checkout session:", error);
    } finally {
      setIsLoading(false); // Reset global loading state
    }
  };

  return (
    <div className="modal-content">
      <h2 className="modal-title">Pirkti Žetonus</h2>
      <p className="modal-description">Pasirinkite jums norimą paketą, kad toliau galėtumėte analizuoti sapnus</p>
      <div className="package-container">
        <div className="package-card">
          <h3>Mini</h3>
          <p>30 žetonų</p>
          <button
            onClick={() => handlePurchase("price_1PLhgpDSm3CeQT7opwmt2oU5", 30)}
            disabled={isLoading}
          >
            {isLoading ? <ClipLoader size={20} color="#fff" /> : "€4.99"}
          </button>
        </div>
        <div className="package-card">
          <h3>Medi</h3>
          <p>150 žetonų</p>
          <button
            onClick={() => handlePurchase("price_1PLhgnDSm3CeQT7oIZfYZJia", 150)}
            disabled={isLoading}
          >
            {isLoading ? <ClipLoader size={20} color="#fff" /> : "€19.99"}
          </button>
        </div>
        <div className="package-card">
          <h3>Maxi</h3>
          <p>500 žetonų</p>
          <button
            onClick={() => handlePurchase("price_1PLhgkDSm3CeQT7oPeFlh4Ap", 500)}
            disabled={isLoading}
          >
            {isLoading ? <ClipLoader size={20} color="#fff" /> : "€49.99"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PurchaseCoinsModal;
