import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  onAuthStateChanged,
} from "firebase/auth";
import { auth, db } from "../firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Alert,
  Grid,
  Divider,
} from "@mui/material";
import { styled } from "@mui/system";
import TextField from "@mui/material/TextField";
import googleLogo from "../images/google logo.png";
import VerificationCard from "./VerificationCard";

const WhiteTextField = styled(TextField)(/* Your existing styles */);

const LoginCard = ({ setIsRegister }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [showVerificationCard, setShowVerificationCard] = useState(false);
  const [unverifiedEmail, setUnverifiedEmail] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user?.emailVerified) {
        navigate("/dashboard");
      }
    });
    return unsubscribe;
  }, [navigate]);

  const handleLogin = async () => {
    if (!email || !password) {
      setError("Prašome įvesti el. paštą ir slaptažodį");
      return;
    }

    setLoading(true);
    setError("");

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      if (user.emailVerified) {
        navigate("/dashboard");
      } else {
        setUnverifiedEmail(email);
        setShowVerificationCard(true);
      }
    } catch (error) {
      if (error.code === "auth/user-not-found") {
        setError("Vartotojas su šiuo el. paštą nerastas.");
      } else if (error.code === "auth/invalid-credential") {
        setError("Neteisingi prisijungimo duomenys.");
      } else {
        setError("Įvyko klaida. Bandykite dar kartą.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordReset = async () => {
    if (!email) {
      setError("Prašome įvesti savo el. paštą");
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setResetEmailSent(true);
      setError("");
    } catch (error) {
      if (error.code === "auth/user-not-found") {
        setError("Vartotojas su šiuo el. paštą nerastas.");
      } else {
        setError("Įvyko klaida siunčiant slaptažodžio atstatymo laišką.");
      }
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    setLoading(true);

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const userDocRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userDocRef);

      if (!userDoc.exists()) {
        await setDoc(userDocRef, {
          email: user.email,
          coins: 5,
          dreams: [],
        });
      }

      navigate("/dashboard");
    } catch (error) {
      if (error.code === "auth/popup-closed-by-user") {
        setError("Prisijungimo langas buvo uždarytas.");
      } else {
        setError("Įvyko klaida prisijungiant per Google.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleVerificationConfirmed = () => {
    setShowVerificationCard(false);
    navigate("/dashboard");
  };

  if (showVerificationCard) {
    return (
      <VerificationCard
        onVerify={handleVerificationConfirmed}
        email={unverifiedEmail}
      />
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Typography component="h1" variant="h5" sx={{ color: "#2C3E50" }}>
        Prisijungti
      </Typography>
      <Box component="form" noValidate>
        <WhiteTextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="El. paštas"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <WhiteTextField
          margin="normal"
          required
          fullWidth
          id="password"
          label="Slaptažodis"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {error && <Alert severity="error">{error}</Alert>}
        {resetEmailSent && <Alert severity="success">Slaptažodžio atstatymo laiškas išsiųstas!</Alert>}
        <Button
          fullWidth
          variant="contained"
          onClick={handleLogin}
          disabled={loading}
          sx={{ mt: 2, mb: 2, backgroundColor: "#fb639c" }}
        >
          {loading ? <CircularProgress size={24} /> : "Prisijungti"}
        </Button>

        {/* Separator */}
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 1 }}>
          <Divider sx={{ flex: 1, bgcolor: "#2C3E50" }} />
          <Typography sx={{ color: "#2C3E50" }}>ARBA</Typography>
          <Divider sx={{ flex: 1, bgcolor: "#2C3E50" }} />
        </Box>

        {/* Google Sign-In Button */}
        <Button
          fullWidth
          variant="outlined"
          onClick={handleGoogleSignIn}
          disabled={loading}
          sx={{
            mt: 1,
            mb: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
          }}
        >
          <img src={googleLogo} alt="Google Logo" style={{ height: "25px", width: "25px" }} />
          Prisijungti su Google
        </Button>

        <Grid container sx={{ textAlign: "center"}}>
          <Grid item xs={12}>
            <Button
              href="#"
              variant="body2"
              onClick={() => setIsRegister(true)}
              sx={{ color: "#2C3E50"}}
            >
              Neturite paskyros? Registruotis
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              href="#"
              variant="body2"
              onClick={handlePasswordReset}
              sx={{ color: "#2C3E50" }}
            >
              Pamiršau slaptažodį
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default LoginCard;
