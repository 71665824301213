import React from "react";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import ServicesSection from "../components/ServicesSection";
import AboutSection from "../components/AboutSection";
import ReviewsSection from "../components/ReviewsSection";
import Footer from "../components/Footer";
import FAQSection from "../components/FAQSection"
import "../styles/Home.css"

const Home = () => {
  return (
    <div className="home">
      <Navbar />
      <HeroSection />
      <ServicesSection />
      <AboutSection />
      <ReviewsSection />
      <FAQSection/>
      <Footer />
    </div>
  );
};

export default Home;
