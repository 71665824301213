import React, { useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import { Link } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css"; // Import Font Awesome icons
import "../styles/navbar.css";
import Logo from "../images/logo.png";
const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav className="navbar">
      {/* Logo */}
      <div className="navbar-logo">
        <img className="navbar-logo-img" src={Logo} alt="Sapnasis Logo" />
        <h1>Sapnasis</h1>
      </div>

      {/* Hamburger Icon */}
      <div className="burger-icon" onClick={toggleMenu}>
        {isMenuOpen ? <i className="fas fa-times"></i> : <div className="burger-lines"></div>}
      </div>

      {/* Navbar Links */}
      <div className={`navbar-links ${isMenuOpen ? "open" : ""}`}>
        <ScrollLink to="hero" className={"navbar-link"} smooth duration={500} onClick={closeMenu}>
          Pradžia
        </ScrollLink>
        <ScrollLink to="services-section" className={"navbar-link"}  smooth duration={500} onClick={closeMenu}>
          Paslaugos
        </ScrollLink>
        <ScrollLink to="about-section" className={"navbar-link"}  smooth duration={500} onClick={closeMenu}>
          Apie
        </ScrollLink>
        <ScrollLink to="reviews-section" className={"navbar-link"}  smooth duration={500} onClick={closeMenu}>
          Atsiliepimai
        </ScrollLink>
        <ScrollLink to="faq-section" className={"navbar-link"}  smooth duration={500} onClick={closeMenu}>
          D.U.K
        </ScrollLink>
        <Link to="/login" onClick={closeMenu} className="navbar-button">
          Prisijungti
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
