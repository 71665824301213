import React, { useState } from "react";
import "../styles/appBar.css";

const AppBar = ({ coins, userInitial, onLogout, onViewChange }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen((prev) => !prev);
  };

  const handleMenuClose = (view) => {
    onViewChange(view, Date.now(), true); // Add reset flag
    setMenuOpen(false); // Close the side menu
  };

  return (
    <>
      <header className="app-bar">
        <div className="toolbar">
          <button className="burger-icon" onClick={handleMenuToggle}>
            <i className="fa-solid fa-bars"></i>
          </button>
          <div className="user-container">
            <span className="coins-text">
              <i className="fa-solid fa-coins"></i> {coins}
            </span>
            <div className="user-avatar">{userInitial}</div>
          </div>
        </div>
      </header>

      <div className={`side-menu ${menuOpen ? "open" : ""}`}>
        <div className="menu-header">
          <button className="close-icon" onClick={handleMenuToggle}>
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
        <ul className="menu-links">
          <li onClick={() => handleMenuClose("DreamForm")}>
            <i className="fa-solid fa-wand-magic-sparkles"></i> Analizuoti
          </li>
          <li onClick={() => handleMenuClose("MyDreams")}>
            <i className="fa-solid fa-moon"></i> Mano sapnai
          </li>
          <li onClick={() => handleMenuClose("PurchaseCoins")}>
            <i className="fa-solid fa-coins"></i> Žetonai
          </li>
          <li
            onClick={() => {
              handleMenuToggle();
              onLogout();
            }}
            className="logout-link"
          >
            <i className="fa-solid fa-sign-out-alt"></i> Atsijungti
          </li>
        </ul>
        <div className="help-section">
          <p className="help-title">Pagalba</p>
          <a href="mailto:pagalba@example.com" className="help-email">
            sapnasisinfo@gmail.com
          </a>
        </div>
      </div>
      <div
        className={`menu-overlay ${menuOpen ? "visible" : ""}`}
        onClick={handleMenuToggle}
      ></div>
    </>
  );
};

export default AppBar;
