import React from "react";
import "../styles/savedDreamCard.css";

const SavedDreamCard = ({ dream, onClick }) => {
  const truncatedDescription = dream.description.length > 18
    ? dream.description.substring(0, 18) + "..."
    : dream.description;

  return (
    <div className="saved-dream-card" onClick={() => onClick(dream)}>
      <div className="card-content">
        <span className="card-title">{truncatedDescription}</span>
        <i className="fa-solid fa-arrow-right"></i>
      </div>
    </div>
  );
};

export default SavedDreamCard;
