import React from "react";
import "../styles/reviewsSection.css";
import reviewIcon from "../images/reviewIcon.png";
import { Link } from "react-router-dom";

const reviews = [
  {
    name: "Viktorija K.",
    role: "Klientė",
    comment:
      "Tokio dalyko dar nemačiau Lietuvoje. Tikrai labai naudingas įrankis sapnų analyzei. Nustebino tai, kad bet kokį sapną su betkokiom detalėm galima išanalizuoti žaibišku greičiu. Niekur kitur to nemačiau. Naudoju kas dieną ir daug sužinau apie savo pasąmonės ženklus. Liuks!",
    stars: 5,
  },
  {
    name: "Audra L.",
    role: "Klientė",
    comment:
      "Ačiū jums Sapnasis už šį nuostabų įrankį! Negalėjau patikėti kokios detalios sapnų analyzės. Tiesiog nustebino labai. Labiausiai nustebino tai ant kiek detalų sapną gali išanalizuoti ir pateitki jo reikšmę. Siūlau visiem išmėginti tikrai daug sužinosite apie savo sapnus.",
    stars: 5,
  },
  {
    name: "Gabija S.",
    role: "Klientė",
    comment:
      "Iš pradžių galvojau, kad tai tik paprasti sapnų paaiškinimai. Bet pirmą kartą pamėginus mane labai nustebino. Įrašiau savo sapną su visom detalėm ir gavau analyzę akimirksniu. Ir labai nustebino tai, kad yra atsižvelgiama į pateiktas sapno detales ir palei tai formuojama analyzė.",
    stars: 5,
  },
];
const ReviewsSection = () => (
  <section id="reviews-section" className="reviews-section">
    <div className="reviews-header">
      <h2>Atsiliepimai</h2>
      {/* Added a short intro paragraph with relevant keywords */}
      <p>
        Sužinokite, kodėl <strong>Sapnasis</strong> yra vertinamas kaip vienas geriausių
        <strong> sapnų analizės</strong> ir <strong>sapnų interpretacijos</strong> įrankių
        Lietuvoje.
      </p>
    </div>
    <div className="review-cards-container">
      {reviews.map((review, index) => (
        <div className="review-card" key={index}>
          <div className="review-header">
            {/* Updated alt to reference brand or context */}
            <img
              src={reviewIcon}
              alt="Sapnasis vartotojas"
              className="review-icon"
            />
            <div>
              <h3 className="review-name">{review.name}</h3>
              <p className="review-role">{review.role}</p>
            </div>
          </div>
          <p className="review-comment">"{review.comment}"</p>
          <div className="review-rating">
            {"★".repeat(review.stars).padEnd(5, "☆")}
          </div>
        </div>
      ))}
    </div>
    <Link to="/login" className="cta-button">
      Prisijungti
    </Link>
  </section>
);

export default ReviewsSection;
