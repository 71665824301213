import React from "react";
import "../styles/aboutSection.css";
import teamSvg from "../images/team.svg";
import { Link } from "react-router-dom";

const AboutSection = () => (
  <section id="about-section" className="about-section">
    <div className="about-content">
      <div className="about-image-container">
        {/* Updated alt text to include relevant keywords */}
        <img src={teamSvg} alt="Sapnasis komanda" className="about-image" />
      </div>
      <div className="about-text">
        <h2>
          Būkite <span className="highlight">tikri</span> savo veiksmuose
        </h2>
        {/* Expanded paragraph to naturally add Lithuanian keywords */}
        <p>
          Tiksliai įvertinkite, kokią žinutę jums siunčia jūsų sapnai, kad
          galėtumėte geriau suprasti save ir savo pasąmonę. Su „Sapnasis“ galite
          atlikti išsamią <strong>sapnų analizę</strong> pasitelkdami
          pažangias technologijas, leidžiančias atskleisti sapnų
          reikšmes bei simboliką.
        </p>
        <p>
          Nesvarbu, ar siekiate <strong>sapnų interpretacijos</strong> kaip
          asmeninio augimo įrankio, ar norite suprasti pasikartojančius
          sapnus, mūsų komanda yra pasiryžusi padėti. Tikime, kad gilus
          supratimas apie <strong>pasąmonės ženklus</strong> gali padėti
          rasti geresnį ryšį su savo mintimis, emocijomis ir ateities
          tikslais.
        </p>
        <div className="about-icons">
          <div className="about-icon">
            <i className="fa-solid fa-pen-to-square"></i>
            <h3>Tikslios analizės</h3>
          </div>
          <div className="about-icon">
            <i className="fa-solid fa-face-smile"></i>
            <h3>Emocinė būklė</h3>
          </div>
          <div className="about-icon">
            <i className="fa-solid fa-brain"></i>
            <h3>Pasąmonės ženklai</h3>
          </div>
        </div>
        <div className="cta-button-container">
          <Link to="/login" className="cta-button">
            Noriu!
          </Link>
        </div>
      </div>
    </div>
  </section>
);

export default AboutSection;
