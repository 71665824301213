import React from 'react';
import SavedDreamCard from './SavedDreamCard';
import '../styles/myDreams.css';

const MyDreams = ({ savedDreams, handleCardClick }) => {
  return (
    <>
      <div className="dreams-header">
        <i className="icon-left"></i>
        <h4 className="dreams-title">Mano Sapnai</h4>
        <i className="icon-right"></i>
      </div>
      <div className="dreams-container">
        {savedDreams.length > 0 ? (
          <div className="dreams-grid">
            {[...savedDreams].reverse().map((dream, index) => ( // Reverse the array
              <div className="dreams-grid-item" key={index}>
                <SavedDreamCard dream={dream} onClick={handleCardClick} />
              </div>
            ))}
          </div>
        ) : (
          <p className="no-dreams">Kolkas nėra sapnų...</p>
        )}
      </div>
    </>
  );
};

export default MyDreams;
